import { Auth } from 'aws-amplify';

async function signup(username, email, password) {
  try {
    // console.log("Email", email)
    // console.log("Username",)
    const { user } = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,          // optional
        // phone_number,   // optional - E.164 number convention
        // other custom attributes 
      },
      autoSignIn: { // optional - enables auto sign in after user is confirmed
        enabled: true,
      }
    });
    // console.log(user);
    return user

  } catch (error) {
    alert(error)
    console.log("error", error)
    return {
        error,
        tag: "error"
    }
    
  }
}

export default signup
import React from "react";
import Personalaccountnavbar from "./Personalaccountnavbar";
import { Grid, useMediaQuery } from "@mui/material";
import Querybox from "./Querybox";

import getuser from "../aws_utils/getAuthuser";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal'
import { Flex, Image, Text, View } from "@aws-amplify/ui-react";

import getUserProjects from "../realm-scripts/getUserProjects";
import { motion } from 'framer-motion'
import { fadeIn, staggerContainer, textVariant } from '../aws_utils/motion';
import ProjectsDisplay from "./ProjectsDisplay";



function DbhaiHome() {

  const [user, setuser] = React.useState("")
  const navigate = useNavigate()

  // Auth navigation useEffect
  React.useEffect(() => {
    const GetUser = async () => {
    const datauser = await getuser()

   
    // console.log("Logged In User Atrributes", datauser.attributes)

     
     if(datauser === "The user is not authenticated"){
         console.log("The user is not authenticated")
         navigate("/")
         return
     } else {
        //  navigate("Home")
        // window.scrollTo(0,0,"smooth");
         setuser(datauser)
         return
     }
         
     }
    GetUser()
 // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [])

  const [welcomeuser, setwelcomeuser] = React.useState(false)
  const closeModal = () => {
    setwelcomeuser(false)
  }
  
  React.useEffect(() => {
    const GetProjects = async () => {
        const projects = await getUserProjects()
        // console.log("Projects", projects)
        if(projects.length === 0){
          setwelcomeuser(true)
        } else {
          setwelcomeuser(false)
        }
    }
    GetProjects()
}, [])

  const matches = useMediaQuery('(min-width:450px)');
  const [selectprojectmodal, setselectprojectmodal] = React.useState(false)
  const closeSelectProjectModal = () => {
    setselectprojectmodal(false)
    // window.scrollTo(0,0);
    // navigate("/Home");
  }
  const openSelectProjectModal = () => {
    setselectprojectmodal(true)
  }
  const [projectname, setprojectname] = React.useState("")
  const currentproject = async (projectname) => {
    // console.log("Current Project Name", projectname)
    setprojectname(projectname)  
  }

  const [projectSelected, setprojectSelected] = React.useState(false)
  React.useEffect(() => {
    // console.log("Project Name", projectname)
    // console.log("project Selected", projectSelected)
    if(projectname === ""){
      // console.log("No project Selected")
      setprojectSelected(false)
    } else {
      // console.log("Project Selected")
      setprojectSelected(true)
    }
  }, [projectname])
  

  
// No project selected modal
  // React.useEffect(() => {
  //   const ProjectModal = async () => {
  //     const projects = await getUserProjects()
  //     if(projectname === "" && projects.length >= 1){
  //       setselectprojectmodal(true)
  //     }
  //   }
  //   ProjectModal()
  // }, [projectname])
  
  // const [jsonQueryData, setjsonQueryData] = React.useState([])
  // const [mongoCollectionName, setmongoCollectionName] = React.useState("")
  // const mongocardselected = async (collectionname) => {

  //   console.log("Mongo Collection Selected", collectionname)
  //   // console.log("Mongo Data Selected", queryData)
    
  //   // setjsonQueryData(queryData);
  //   setmongoCollectionName(collectionname)
  // }

  // const [mongotoggleoff, setmongotoggleoff] = React.useState(false)
  // const mongotoggle = async (toggled) => {
  //   setmongotoggleoff(toggled)
  // }


  const [documentData, setdocumentData] = React.useState([])
  const documentselected = async (docdata) => {
    // console.log("Docdata from Home", docdata)
    setdocumentData(docdata)
  }

  const [documenttoggleoff, setdocumenttoggleoff] = React.useState(false)
  const documenttoggle = async (toggled) => {
    setdocumenttoggleoff(toggled)
  }

  // Query box reset
  const [resetQueryResponse, setresetQueryResponse] = React.useState(false)
  // const restQueryResp = async () => {
  //   // console.log("Prop passed", prop)
  //   setresetQueryResponse(true)
  // }
  const restQRState = async () => {
    // console.log("Prop passed", prop)
    setresetQueryResponse(false)
  }


   
  return (

   <View className="relative flex flex-col bg-slate-400 h-[100%] w-[100vw]">
    <Personalaccountnavbar
    user={user}
    />
 <Image
    // className="relative dark:drop-shadow-[0_0_0.3rem_#ffffff70] dark:invert"
    src="/gateleakv2.gif"
    alt=" "
    width="100%"
    height="100%"
    objectFit="cover"
    // objectPosition="top"
    position="fixed"
    // overflow="scroll"
    />

 {/* Project Card at the moment */}
    <View 
    className="relative flex-col w-full h-[100vh] mb-[200px] "
    
    // backgroundColor="rgba(222,136,101,.7)"
    // backgroundColor="grey"
    // // className="w-[100vw]"
    // position="relative"
    // display="flex"
    // direction="column"
    // width="100vw"
    >

   
  
    
   

{/* Project Card at the moment */}
   
    {/* <span className='hash-span' id={`Project`}> */}

    <View
    id={`Project`}
    className="relative flex flex-col pt-[85px] "
  


    >
      
      {/* // no project and slect project nelow  */}
      <motion.section
          variants={staggerContainer()}
          initial="hidden"
          whileInView="show"
          viewport={{once: false, amount: 0.5}}
          >
     
    <motion.div variants={textVariant()}
    >
     
     <View className="relative flex flex-col pl-[20px] pr-[20px]">
        <Text
        color="white"
        fontSize={matches ? "40px" : "30px"}
        fontWeight="extrabold"
        display={projectSelected ? "block" : "none"}
        // backgroundColor="red"
        >
        {`Project ${projectname}`}
        </Text>

        <Text
        color="white"
        fontSize={matches ? "40px" : "30px"}
        fontWeight="extrabold"
       
        display={projectSelected ? "none" : "block"}
        >
        {`No Project Selected`}
        </Text>
        {projectSelected ? 
        <a href="#Agents">
        <motion.button
        whileHover={{scale : 1.1,
        paddingLeft : "10px"
      }}
        whileTap={{scale: 0.9}}
        // viewport={{once: false, amount: 0.5}}
        
        >
        <Text
        color="white"
        fontSize={matches ? "20px" : "15px"}
        fontWeight="bold"
        paddingBottom="5px"
       
        // backgroundColor="red"
        >
        {`Click Here or "Agents" Above...`}
        </Text>
        </motion.button>
        
        
        </a> :
        <Text
        color="white"
        fontSize={matches ? "20px" : "15px"}
        fontWeight="bold"
        paddingBottom="5px"
       
      
        >
        {`Select Project below or Create New Project ...`}
        </Text>
}
      </View>

     
      </motion.div>

      </motion.section>
      
      
        
        
      <motion.section
          variants={staggerContainer()}
          initial="hidden"
          whileInView="show"
          viewport={{once: false, amount: 0.5}}
          >
    
            
            <motion.div
              variants={fadeIn("right","spring", 0.1, 0.75)}
            //   className="rounded-[20px] green-pink-gradient p-[1px]  shadow-card"
              >
    
      
      <ProjectsDisplay
      currentproject={currentproject}
      // mongoselected={mongocardselected} 
      // mongotoggle={mongotoggle}
      // documentselected={documentselected} 
      // documenttoggle={documenttoggle}
      // resetQueryResponse={restQueryResp} 
      />
      {/* </View> */}
      {/* </Flex> */}

      </motion.div>
      </motion.section>
     
    </View>
    {/* </span> */}
    


        <Modal
            isOpen={welcomeuser}
            onRequestClose={closeModal}
            style={{
                overlay: {
                position: 'fixed',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                zIndex: 999,
                padding: "20px"
                },
                content: {
                position: 'relative',
        //   display
                top: '0px',
                left: '0px',
                right: '0px',
                bottom: '0px',
                border: '1px solid #ccc',
                // background: 'rgba(222,136,101,1)',
                background: 'grey',
                
        //   width: "302px",
        //   overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '10px',
                outline: 'none',
                padding: '40px'
                }
            }}
            >
              <Flex
              position="relative"
              justifyContent="center"
              alignItems="center"
              direction="column"
              textAlign="center"
              >
            {/* <ReactMarkdown>  */}
            <Text
            position="relative"
            color="white"
            fontSize="20px"
            fontWeight="extrabold"
            // lineHeight="30px"
            // padding=" 20px 20px 20px 20px"
            >
              
                Welcome!
                

            </Text>
            <Text
             position="relative"
             color="white"
             paddingTop="20px"
             >
            1. Create a new Project!
            </Text>
            <Text
             position="relative"
             color="white">

            2. Select an Agent!

            </Text>
            <Text
             position="relative"
             color="white">

       
            3. Query away!

            </Text>
          
              </Flex> 
            {/* </ReactMarkdown> */}
            {/* <SignIn /> */}

        </Modal>

        <Modal
            isOpen={selectprojectmodal}
            onRequestClose={closeSelectProjectModal}
            style={{
                overlay: {
                position: 'fixed',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                zIndex: 999,
                padding: "20px"
                },
                content: {
                position: 'relative',
        //   display
                top: '0px',
                left: '0px',
                right: '0px',
                bottom: '0px',
                border: '1px solid #ccc',
                background: 'grey',
        //   width: "302px",
        //   overflow: 'auto',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '10px',
                outline: 'none',
                padding: '40px'
                }
            }}
            >
              <Flex
              position="relative"
              justifyContent="center"
              alignItems="center"
              direction="column"
              >
            {/* <ReactMarkdown>  */}
             <Text
            position="relative"
            color="white"
            fontSize="20px"
            fontWeight="bold"
            textAlign="center"
            // lineHeight="30px"
            // padding=" 20px 20px 20px 20px"
            >
              
                No Project Selected! 
                

            </Text>
            <Text
            position="relative"
            color="white"
            fontSize="20px"
            fontWeight="normal"
            textAlign="center"
            >
            Select or Create Project then choose an Agent!
            </Text>
         

            </Flex> 
            {/* </ReactMarkdown> */}
            {/* <SignIn /> */}

        </Modal> 

    </View>



    {/* Agents Card at the moment */}
    <View
    className=" h-[100vh] pt-[80px]"
    id={`Agents`}
    // container item 
    // xs={6}
    // display="block"
    // justifyContent="center"
    // alignItems="center"
    // width="100%"   
    // minHeight="100vh"
    // maxHeight="auto"
    // // height="auto"
    // position="relative"
    // paddingLeft="20px"
    // paddingRight="20px"
    // paddingTop="0px"
    // paddingBottom="20px"
    // backgroundColor="green"
    // backgroundColor="rgba(225,255,255,.3)"
    
    >





    <motion.section
          variants={staggerContainer()}
          initial="hidden"
          whileInView="show"
          viewport={{once: false, amount: 0.5}}
          >
     
    <motion.div variants={textVariant()}
    // className=" bg-slate-600"
    >
     
      <View
      position="relative"
      width="100%"
      className="pl-5"
     
      paddingBottom="2px"
      >
        <a href="#Project">
        <motion.button
        whileHover={{
          scale : 1.1,
          paddingLeft : "25px"
        }}
        whileTap={{
          scale: 0.9,
          
        }}
        
        >
        <Text
        color="white"
        fontSize={matches ? "40px" : "30px"}
        fontWeight="extrabold"
        textAlign="left"
        // backgroundColor="red"
        >
        {`Project: ${projectname || "No Project Selected"}`}
        </Text>
    </motion.button>
        </a>


        <Text
        color="white"
        fontSize={matches ? "20px" : "15px"}
        fontWeight="bold"
        display={projectSelected ? "block" : "none"}
        paddingBottom="5px"
        // backgroundColor="red"
        >
        {`Select an Agent ...`}
        </Text>

      </View>
      
    </motion.div>
    </motion.section>
      
      {/* <motion.section
          variants={staggerContainer()}
          initial="hidden"
          whileInView="show"
          viewport={{once: false, amount: 0.5}}
          >
    
            
            <motion.div
              variants={fadeIn("right","spring", 0.5, 0.75)}
            //   className="rounded-[20px] green-pink-gradient p-[1px]  shadow-card"
              >  */}


         <View className="bg-slate-400 w-full">
          
          <motion.section
          variants={staggerContainer()}
          initial="hidden"
          whileInView="show"
          viewport={{once: true, amount: 0.5}}
          
          >
    
            
        <motion.div
            variants={fadeIn("right","spring", 0.1, 0.75)}
            
              >   

{projectSelected ? 
     

     

      <Querybox 
      projectname={projectname}
      // mongocollectionname={mongoCollectionName}
      // mongotoggle={mongotoggleoff}
      // querydata={jsonQueryData} 
      documentdata={documentData} 
      documentselected={documentselected}
      openselectprojectmodal={openSelectProjectModal}
      // dataselected={false} 
      doucmenttoggle={documenttoggleoff}
      documentstoggle={documenttoggle}
      resetQueryResponse={resetQueryResponse}
      restQRState={restQRState}
      />

       : <></>
}

          </motion.div>
          </motion.section>
          </View>
          {/* </Grid> */}

      <a className="relative flex pl-5 pr-5" href="#Project">
      
      <Grid
      position="relative"
      width="100%"
      height="100px"
      backgroundColor="rgba(225,255,255,.3)"
      // backgroundColor="rgba(222,136,101,.5)"
      boxShadow="0px 5px 15px rgba(0, 0, 0, 0.4)"
      borderRadius="15px"
      justifyContent="center"
      alignItems="center"
      // display="flex"
      // marginLeft="10px"
      // marginRight="10px"
      // paddingTop="30px"
      display={projectSelected ? "none" : "flex"}
      // paddingBottom="20px"


      >
        <motion.button
        whileHover={{scale : 1.1}}
        whileTap={{scale: 0.9}}
        
        >
        <Text
        textAlign="center"
        color="white"
        fontWeight="bolder"
        fontSize="x-large"
        width="auto"
        // className="hover: text-[rgba(222,136,101,.5)]"
        >
          Click Here to Select Project
        </Text>
        </motion.button>
      
      </Grid>      
      </a> 

    </View>
   {/* Agents Card at the moment */}
    </View>
   
 


  

  );
}

export default DbhaiHome;